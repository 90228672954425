import ApiConstant from "./apiConstant";

const rUrl = ApiConstant.CDN_REACT;

const ImageConstant = {
  notiSuccess: `${rUrl}/common/noti-success.png`,
  notiError: `${rUrl}/common/noti-error.png`,
  notiWarning: `${rUrl}/common/noti-warning.png`,
  notiInfo: `${rUrl}/common/noti-info.png`,
  diceNum1: `${rUrl}/common/dice_num1.png`,
  diceNum2: `${rUrl}/common/dice_num2.png`,
  diceNum3: `${rUrl}/common/dice_num3.png`,
  diceNum4: `${rUrl}/common/dice_num4.png`,
  diceNum5: `${rUrl}/common/dice_num5.png`,
  diceNum6: `${rUrl}/common/dice_num6.png`,
  k3Game: {
    k3HomeLogo: `${rUrl}/k3/k3_lotre_name.png`,
    diceBg: `${rUrl}/k3/dice_bg.png`,
    diceLeft: `${rUrl}/k3/dice_left.png`,
    diceRight: `${rUrl}/k3/dice_right.png`,
  },
  k5dGame: {
    k5dHomeLogo: `${rUrl}/k5/k5_lotre_name.png`,
    arrowLeft: `${rUrl}/k3/dice_left.png`,
    arrowRight: `${rUrl}/k3/dice_right.png`,
  },
  winGoGame: {
    winGoHomeLogo: `${rUrl}/win/win_go_bg.png`,
  },
  noData: `${rUrl}/common/no_result.png`,
  coin: `${rUrl}/common/coin.png`,
  closeIcon: `${rUrl}/common/close.png`,
  commonPopUpTitle: `${rUrl}/common/common_popup_title.png`,
  betLose: `${rUrl}/common/bet_lose.png`,
  betWin: `${rUrl}/common/bet_win.png`,
  betResult: `${rUrl}/common/bet_result.png`,
};

export default ImageConstant;
