import React, { useState } from "react";
import ImageConstant from "../../../common/constants/imageConstant";
import styles from "./GameHeader.module.css";
import CountdownTimer from "../countDownTimer/CountdownTimer";
import NumFormat from "../numformat/NumFormat";
import CommonConstant from "../../../common/constants/commonConstant";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import CommonMapper from "../../../common/mappers/commonMapper";
import { gameAction } from "../../../store/slices/gameSlice";
import GameRuleModal from "../gameRuleModal/GameRuleModal";
import IconInfo from "../../icon/IconInfo";
import { Switch } from "@mui/material";
import useMutePopUp from "../../hook/useMutePopUp";
import { useStyle } from "./GameHeader.style";

const GameHeader = () => {
  const { popUpMuted, handleMutePopUp } = useMutePopUp(CommonConstant.Storage.MuteResultPopUp);
  const classes = useStyle();

  // Redux
  const { game, gameTime, periodId, walletBalance } = useSelector(
    (state) => ({
      game: state.game.game,
      gameTime: state.game.gameTime,
      periodId: state.game.periodId,
      walletBalance: state.user.walletBalance,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  // State
  const [showGameRules, setShowGameRules] = useState(false);

  // Variable

  // Function
  const toggleGameRules = () => setShowGameRules(!showGameRules);

  // Reducer actions
  const updateGameTime = (gameTime) => {
    dispatch(gameAction.setGameTime(gameTime));
    dispatch(gameAction.setGameResult(null));
    // dispatch(gameAction.setRecordType(GameConstant.RecordType.RoundHistory));
  };

  return (
    <>
      <div className={styles.gameBettingContainer}>
        <div className={styles.logoContainer}>
          <img src={CommonMapper.GameImage[game]} alt={`${game}`} />
          <div className={styles.walletInfo}>
            <div className={styles.totalBalance}>Total Balance</div>
            <div className={styles.memberWallet}>
              <img src={ImageConstant.coin} alt="coin" width={20} height={21} />
              <NumFormat className={styles.memberBalance} value={walletBalance} />
            </div>
          </div>
        </div>
        <div className={styles.timePeriodContainer}>
          <div className={styles.timePeriodTitle}>Round Time</div>
          <div className={styles.gameRules} onClick={toggleGameRules}>
            <IconInfo />
            <div className={styles.gameRulesTitle}>Game Rules</div>
          </div>
        </div>
        <div className={styles.timePeriodOption}>
          {CommonConstant.GameTimes.map((time) => (
            <div
              key={time}
              className={`text-xs sm:text-lg ${styles.optionBox} ${gameTime === time && styles.selectedOption}`}
              onClick={() => updateGameTime(time)}
            >
              {time} {`Min${time > 1 ? "s" : ""}`}
            </div>
          ))}
        </div>
        <div className={styles.winningNotice}>
          <span>Result Notice</span>
          <Switch className={classes.winningNoticeswitch} checked={!popUpMuted} onChange={(e) => handleMutePopUp(!e.target.checked)} />
        </div>
        <div className={styles.betContainer}>
          <div className={styles.betBoxHeader}>
            <div className={styles.betBoxTitle}>
              <div className={styles.betMinDisplay}>{gameTime} Minute</div>
              <div>Time left to buy</div>
            </div>
            <div className={styles.betMinContent}>
              <div className={styles.betPeriodID}>{periodId}</div>
              <CountdownTimer selectedGameTime={gameTime} />
            </div>
          </div>
        </div>
      </div>
      <GameRuleModal show={showGameRules} toggle={toggleGameRules} />
    </>
  );
};

export default GameHeader;
