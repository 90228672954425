import BetConstant from "./betConstant";
import WingoConstant from "./winGoConstant";

// Value that would not display to user
const GameConstant = {
  K3: {
    GameType: {
      TotalNumber: "total",
      TwoDuplicateNumber: "two-same",
      ThreeDuplicateNumber: "three-same",
      DifferentNumber: "unlike",
    },
    GameMode: {
      TotalNumber: "total",
      TwoDuplicateNumber: {
        AnyTwoSame: "any-two-same",
        UniqueTwoSame: "unique-two-same",
      },
      ThreeDuplicateNumber: {
        AnyThreeSame: "any-three-same",
        UniqueThreeSame: "unique-three-same",
      },
      DifferentNumber: {
        ThreeDifferent: "three-different",
        ThreeConsecutive: "three-consecutive",
        TwoDifferent: "two-different",
      },
    },
  },
  WinGo: {
    GameType: {
      WinGoBet: "WinGoBet",
    },
    BetList: {
      colorBet: [
        {
          value: "Green",
          joinValue: WingoConstant.BetColor.Green,
        },
        {
          value: "Violet",
          joinValue: WingoConstant.BetColor.Violet,
        },
        {
          value: "Red",
          joinValue: WingoConstant.BetColor.Red,
        },
      ],
      numberBet: [
        {
          value: 0,
          color: WingoConstant.BetColor.MixRed,
        },
        {
          value: 1,
          color: WingoConstant.BetColor.Green,
        },
        {
          value: 2,
          color: WingoConstant.BetColor.Red,
        },
        {
          value: 3,
          color: WingoConstant.BetColor.Green,
        },
        {
          value: 4,
          color: WingoConstant.BetColor.Red,
        },
        {
          value: 5,
          color: WingoConstant.BetColor.MixGreen,
        },
        {
          value: 6,
          color: WingoConstant.BetColor.Red,
        },
        {
          value: 7,
          color: WingoConstant.BetColor.Green,
        },
        {
          value: 8,
          color: WingoConstant.BetColor.Red,
        },
        {
          value: 9,
          color: WingoConstant.BetColor.Green,
        },
      ],
      timerBet: [
        {
          value: 0,
        },
        {
          value: 1,
        },
        {
          value: 5,
        },
        {
          value: 10,
        },
        {
          value: 20,
        },
        {
          value: 50,
        },
        {
          value: 100,
        },
      ],
      bigSmallBet: [
        { value: "Big", color: "blue", joinValue: BetConstant.BetSize.Big },
        { value: "Small", color: "yellow", joinValue: BetConstant.BetSize.Small },
      ],
    },
  },
  K5d: {
    GameType: {
      K5dBet: "K5dBet",
      A: "a",
      B: "b",
      C: "c",
      D: "d",
      E: "e",
      Total: "total",
    },
  },
  Games: {
    K3: "k3",
    WinGo: "wingo",
    K5d: "k5d",
  },
  RecordType: {
    RoundHistory: "roundHistory",
    BetHistory: "betHistory",
  },
  SignalR: {
    roundConnections: ["k3-round", "5d-round", "wingo-round"],
  },
};

export default GameConstant;
