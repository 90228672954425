import GameConstant from "./gameConstant";
import RouteConstant from "./routeConstant";
import BetConstant from "./betConstant";

// Display on UI
const CommonConstant = {
  GameRoutes: [RouteConstant.K3, RouteConstant.K5d, RouteConstant.Wingo],
  GameTimes: [1, 3, 5, 10],
  RecordTypes: [
    { name: "Round History", value: GameConstant.RecordType.RoundHistory },
    { name: "Bet History", value: GameConstant.RecordType.BetHistory },
  ],
  TableHeaders: {
    [GameConstant.Games.K3]: ["Lottery Round", "Total", "Big/Small", "Odd/Even", "Result"],
    [GameConstant.Games.K5d]: ["Lottery Round", "Result", "Total"],
    [GameConstant.Games.WinGo]: ["Lottery Round", "Result", "Big/Small", "Color"],
  },
  GameName: {
    [GameConstant.Games.K3]: "K3",
    [GameConstant.Games.K5d]: "5D",
    [GameConstant.Games.WinGo]: "WinGo",
  },
  K3: {
    GameTypes: [
      {
        value: GameConstant.K3.GameType.TotalNumber,
        name: "Total number",
      },
      {
        value: GameConstant.K3.GameType.TwoDuplicateNumber,
        name: "2 duplicate number",
      },
      {
        value: GameConstant.K3.GameType.ThreeDuplicateNumber,
        name: "3 duplicate number",
      },
      {
        value: GameConstant.K3.GameType.DifferentNumber,
        name: "Different number",
      },
    ],
    GameSections: {
      [GameConstant.K3.GameType.TotalNumber]: [
        { name: "3", odds: 207.36 },
        { name: "4", odds: 69.12 },
        { name: "5", odds: 34.56 },
        { name: "6", odds: 20.74 },
        { name: "7", odds: 13.83 },
        { name: "8", odds: 9.88 },
        { name: "9", odds: 8.3 },
        { name: "10", odds: 7.68 },
        { name: "11", odds: 7.68 },
        { name: "12", odds: 8.3 },
        { name: "13", odds: 9.88 },
        { name: "14", odds: 13.83 },
        { name: "15", odds: 20.74 },
        { name: "16", odds: 34.56 },
        { name: "17", odds: 69.12 },
        { name: "18", odds: 207.36 },
        { name: "Big", odds: 1.92 },
        { name: "Small", odds: 1.92 },
        { name: "Odd", odds: 1.92 },
        { name: "Even", odds: 1.92 },
      ],
      [GameConstant.K3.GameType.TwoDuplicateNumber]: {
        SingleValues: [1, 2, 3, 4, 5, 6],
        DoubleValues: [11, 22, 33, 44, 55, 66],
      },
      [GameConstant.K3.GameType.ThreeDuplicateNumber]: [111, 222, 333, 444, 555, 666],
      [GameConstant.K3.GameType.DifferentNumber]: [1, 2, 3, 4, 5, 6],
    },
  },
  K5d: {
    GameSections: {
      Section1: [
        { name: "Big 1.98", value: BetConstant.BetSize.Big },
        { name: "Small 1.98", value: BetConstant.BetSize.Small },
        { name: "Odd 1.98", value: BetConstant.BetPattern.Odd },
        { name: "Even 1.98", value: BetConstant.BetPattern.Even },
      ],
      Section2: Array.from({ length: 10 }).map((_, idx) => idx),
    },
    GameTypes: [
      {
        value: GameConstant.K5d.GameType.A,
        name: "A",
      },
      {
        value: GameConstant.K5d.GameType.B,
        name: "B",
      },
      {
        value: GameConstant.K5d.GameType.C,
        name: "C",
      },
      {
        value: GameConstant.K5d.GameType.D,
        name: "D",
      },
      {
        value: GameConstant.K5d.GameType.E,
        name: "E",
      },
      {
        value: GameConstant.K5d.GameType.Total,
        name: "Sum",
      },
    ],
    NumberBetOddValue: 9,
  },
  RecordPagination: {
    PerPage: 10,
    MaxPage: 12,
  },
  BetSummaryOptions: {
    AmountOf: [1, 10, 100, 1000],
    Multiplier: [1, 5, 10, 20, 50, 100],
  },
  Storage: {
    AccessToken: "Access-Token",
    Username: "Username",
    SignalRId: "SignalRId",
    MuteResultPopUp: "muteResultPopUp",
  },
  Cookie: {
    RefreshToken: "refreshToken",
    AuthToken: "auth",
    AccessToken: "token",
  },
  ContentType: {
    form: "application/x-www-form-urlencoded",
    json: "application/json",
  },
  HeaderKeys: {
    ContentType: "Content-Type",
    Authorization: "Authorization",
  },
  Methods: {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
    PATCH: "PATCH",
    HEAD: "HEAD",
    OPTIONS: "OPTIONS",
  },
};

export default CommonConstant;
