import React from "react";
import { NumericFormat } from "react-number-format";

const NumFormat = (props) => {
  const {
    value,
    className,
    suffix,
    prefix,
    decimalScale,
    thousandSeparator = true,
  } = props;
  return (
    <NumericFormat
      className={className}
      value={value}
      suffix={suffix}
      prefix={prefix}
      displayType={"text"}
      decimalScale={
        typeof decimalScale === "undefined" || decimalScale === null
          ? 2
          : decimalScale
      }
      fixedDecimalScale={true}
      thousandSeparator={thousandSeparator}
    />
  );
};

export default NumFormat;
