import { createAsyncThunk } from "@reduxjs/toolkit";

import * as Api from "../api/gameApi";
import { gameAction } from "../store/slices/gameSlice";
import { userAction } from "../store/slices/userSlice";

export const getGameResult = createAsyncThunk("game/getGameResult", async ({ game, gameTime }, { dispatch }) => {
  try {
    const { round } = await Api.getGameResult(game, gameTime);
    dispatch(gameAction.prependRoundData({ processedRound: round }));
    dispatch(gameAction.showNormalResult({ result: round.result }));
  } catch (error) {
    console.log(error);
  }
});

export const getPrevRoundWinnings = createAsyncThunk("game/getPrevRoundWinnings", async ({ game, gameTime }, { dispatch }) => {
  try {
    const winningsResp = await Api.getPrevRoundWinnings(game, gameTime);

    const { isWin, winnings, round, resultSummary } = winningsResp;

    // Only win will show special popup
    if (isWin) {
      dispatch(
        gameAction.showWinBetResult({
          result: round.result,
          winAmount: winnings,
          periodId: round.period,
          resultSummary: resultSummary,
        })
      );
      setTimeout(() => dispatch(gameAction.clearWinBetResult()), 3000);
    } else {
      dispatch(gameAction.showNormalResult({ result: round.result }));
    }
  } catch (error) {
    console.log(error);
  }
});

export const getUserGameInfo = createAsyncThunk("game/getUserGameInfo", async ({ game, gameTime }, { dispatch }) => {
  try {
    const response = await Api.getUserGameInfo(game, gameTime);
    const balance = response?.balance || 0;
    const gameInfo = response?.gameInfo || { betHistory: [], currentRound: {}, roundHistory: [] };
    dispatch(gameAction.initUserGameInfo({ gameInfo, walletBalance: parseFloat(balance).toFixed(2) }));
  } catch (error) {
    console.log(error);
  }
});

export const getRoundHistory = createAsyncThunk("game/getRoundHistory", async ({ game, gameTime, pageNumber }, { dispatch }) => {
  try {
    const response = await Api.getRoundHistory(game, gameTime, pageNumber);
    dispatch(gameAction.setGameInfo({ data: response }));
  } catch (error) {
    console.log(error);
  }
});

export const getBetHistory = createAsyncThunk("game/getBetHistory", async ({ game, gameTime, pageNumber }, { dispatch }) => {
  try {
    const response = await Api.getBetHistory(game, gameTime, pageNumber);
    dispatch(gameAction.setGameInfo({ data: response }));
  } catch (error) {
    console.log(error);
  }
});

export const getBalance = createAsyncThunk("game/getBalance", async (_, { dispatch }) => {
  try {
    const balanceResp = await Api.getBalance();
    dispatch(userAction.setWalletBalance(parseFloat(balanceResp).toFixed(2)));
  } catch (error) {
    console.log(error);
  }
});
