import { createAsyncThunk } from "@reduxjs/toolkit";

import * as Api from "../api/authApi";
import { HttpStatusCode } from "axios";
import { setAccessToken, setSignalRId, setUsername } from "../common/helpers/localStorage";

export const authUser = createAsyncThunk("auth/authUser", async ({ sessionId, game }) => {
  try {
    const response = await Api.startSession(sessionId, game);
    if (response.isSuccess) {
      setAccessToken(response.token);
      setUsername(response.username);
      setSignalRId(response.signalRId);
      return response.redirectUrl;
    } else {
      return response.redirectUrl;
    }
  } catch (error) {
    console.log(error);
  }
});
