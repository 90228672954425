import React, { useEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector, batch } from "react-redux";
import { useLocation } from "react-router-dom";
import CommonMapper from "../../common/mappers/commonMapper";
import GameHeader from "../common/gameHeader/GameHeader";
import { gameAction } from "../../store/slices/gameSlice";
import GameBlocker from "../common/gameBlocker/GameBlocker";
import BaseRecord from "./BaseRecord";
import BaseBetContainer from "./BaseBetArea";
import BaseDrawer from "./BaseDrawer";
import BaseResultDisplay from "./BaseResultDisplay";
import ResultModal from "../common/resultModal/ResultModal";
import { objectIsNotEmpty, randomNumber, sleep } from "../../common/helpers/commonHelper";
import * as gameEvent from "../../event/gameEvent";
import GameMapper from "../../common/mappers/gameMapper";
import useGameSignalR from "../hook/useGameSignalR";
import BetResultOverlay from "../common/betResultOverlay/BetResultOverlay";
import useDebounce from "../hook/useDebounce";

const BaseGame = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { game, gameTime } = useSelector(
    (state) => ({
      game: state.game.game,
      gameTime: state.game.gameTime,
    }),
    shallowEqual
  );
  const gameStoreRef = useRef();

  useEffect(() => {
    const selectedGame = CommonMapper.RouteGame[location.pathname];
    dispatch(gameAction.initGame(selectedGame));
  }, [location.pathname, dispatch]);

  useEffect(() => {
    gameStoreRef.current = { gameTime, game };
  }, [gameTime, game]);

  useEffect(() => {
    if (game && gameTime) dispatch(gameEvent.getUserGameInfo({ game, gameTime }));
  }, [game, gameTime, dispatch]);

  const roundEndCallback = async (msg) => {
    try {
      const { gameTime } = gameStoreRef.current;
      console.log(msg);

      const [currRound, processedRound] = msg.data;
      if (Number(msg.game) === Number(gameTime)) {
        if (objectIsNotEmpty(currRound) && objectIsNotEmpty(processedRound)) {
          dispatch(gameAction.gameRoundEnded({ periodId: currRound.period, gameResult: processedRound.result }));
          dispatch(gameEvent.getPrevRoundWinnings({ game: game, gameTime: gameTime }));
          dispatch(gameAction.prependRoundData({ processedRound }));
          await sleep(500);
          dispatch(gameAction.setIsNewResult(false));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const payoutCompletedCallback = useDebounce(() => {
    console.log("Payout Completed Callback");
    dispatch(gameEvent.getBalance());
  }, 1000);

  useGameSignalR([
    { connectionName: GameMapper.SignalRConnection.RoundEnded(game), callback: roundEndCallback },
    { connectionName: GameMapper.SignalRConnection.PayoutCompleted, callback: payoutCompletedCallback },
  ]);

  return (
    <div className="pt-3 no-select">
      <GameHeader />
      <BaseResultDisplay />
      <GameBlocker>
        <BaseBetContainer />
      </GameBlocker>
      <BaseRecord />
      <BaseDrawer />
      <ResultModal />
      <BetResultOverlay />
    </div>
  );
};

export default BaseGame;
