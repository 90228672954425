export const ApiConstant = {
  HOST: window.APP_API_HOST || "http://localhost:3001",
  CDN: window.APP_API_UPLOAD_URL || "http://localhost:5000/uploads/",
  CDN_REACT: window.APP_CDN_REACT || "http://localhost:3001/",
  APIBASE: "/api",
  APIVERSION: "/v1",
  Auth: {
    Base: "/auth",
    AuthUser: "/auth",
    StartSession: "/start-session",
    RefreshAccessToken: "/refresh",
  },
  Game: {
    Base: "/bet",
    GetGameResult: "/game-result",
    GetPrevRoundWinnings: "/prev-round-winnings",
    GetUserGameInfo: "/user-game-info",
    GetRoundHistory: "/round-info",
    GetBetHistory: "/user-bet-info",
    SubmitK3Bet: "/k3",
    submitK5dBet: "/k5d",
    SubmitWinGoBet: "/wingo",
    GetBalance: "/get-balance",
  },
};

export default ApiConstant;
