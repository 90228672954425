const RouteConstant = {
  Home: "/home",
  Login: "/login",
  Auth: "/auth",
  K3: "/k3",
  K5d: "/5d",
  Wingo: "/wingo",
};

export default RouteConstant;
